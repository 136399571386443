@media screen {
    .PortfolioItem__Main {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: var(--content-max-width);
        opacity: 0;
        transform: translateY(var(--reveal-element-offset));
    }

    .PortfolioItem__Main.show {
        opacity: 1;
        transform: translateY(0px);

        transition: ease-out 400ms;
    }

    .PortfolioItem__TitleContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .PortfolioItem__TitleContainer>h4 {
        font-size: var(--medium-font-size);
        font-family: 'Obviously';
        font-weight: 400;
        opacity: 0.6;
    }

    .PortfolioItem__MetaContainer {
        display: grid;
        grid-template-columns: 1fr 304px;
        gap: 48px;
        height: 480px;
    }

    .PortfolioItem__MetaContainer>img {
        border-radius: 16px;
        object-position: center;
        object-fit: cover;
        height: 480px;
        width: 100%;
        max-height: 480px;
        border: 2px solid var(--main-accent-colour);
    }

    .PortfolioItem__Info {
        display: flex;
        flex-direction: column;
        gap: 48px;
    }

    .PortfolioItem__InfoContainer {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .PortfolioItem__InfoContainer>h4 {
        color: var(--main-text-colour);
        font-size: var(--small-font-size);
        font-weight: 700;
    }

    .PortfolioItem__TagContainer {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }

    .PortfolioItem__TechContainer {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }

    .PortfolioItem__TechContainer>.TechCard {
        display: flex;
        padding: 8px;
        align-items: center;
        border-radius: 12px;
        border-width: 2px;
    }

    .DesignTag,
    .FrontendTag,
    .BackendTag {
        display: flex;
        padding: 8px 16px;
        align-items: flex-start;
        border-radius: 6px;
        color: var(--main-text-colour);
        font-size: var(--small-font-size);
        font-weight: 600;
        line-height: 100%;
    }

    .DesignTag {
        background-color: var(--green-accent);
    }

    .FrontendTag {
        background-color: var(--orange-accent);
    }

    .BackendTag {
        background-color: var(--purple-accent);
    }

    .PortfolioItem__Links {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

@media (width < 1132px) {

    .PortfolioItem__MetaContainer {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    .PortfolioItem__MetaContainer>img {
        height: calc(64vw - 48px);
        max-height: none;
    }

    .PortfolioItem__Info {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
    }

}

@media (width < 861px) {


    .PortfolioItem__TitleContainer {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
    }

    .PortfolioItem__Info {
        grid-template-columns: 1fr 1fr;
    }

    .PortfolioItem__TechStack {
        grid-column: 2;
        grid-row: 1/3;
    }

}

@media (width < 516px) {

    .PortfolioItem__Info {
        grid-template-columns: 1fr;
    }

    .PortfolioItem__MetaContainer>img {
        height: calc(64vw - 32px);
        max-height: none;
    }

    .PortfolioItem__TechStack {
        grid-column: unset;
        grid-row: unset;
    }

    .PortfolioItem__TitleContainer {
        gap: 4px;
    }

}