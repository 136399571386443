@media screen {

    .Builder__Container {
        display: flex;
        flex-direction: column;
        gap: 32px;
        max-width: var(--content-max-width);
        width: 100%;
    }

    .Builder {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        border-radius: 24px;
        outline: 4px solid var(--main-accent-colour);
        overflow: hidden;
    }

    .Builder__Content {
        padding: 24px 32px;
        background-color: var(--main-accent-colour);
        display: flex;
        flex-direction: column;
        gap: 24px;
        grid-column-start: 1;
        grid-row-start: 1;
    }

    .Builder__Controls {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .Builder__Images {
        display: flex;
        flex-direction: row;
        gap: 8px;
        padding-block: 2px;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        width: calc(100% + 32px)
    }

    .Builder__Images::-webkit-scrollbar:horizontal {
        display: none;
    }

    .ImageOption {
        padding: 4px;
        border-radius: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: .6;
        border: 2px solid rgba(0, 0, 0, 0);

        transition: opacity 320ms ease-out;
        transition: border 320ms ease-out;
    }

    .ImageOption:hover {
        opacity: 1;

        transition: opacity 320ms ease-out;
    }

    .ImageOption__Selected {
        border: 2px solid var(--main-action-colour);
        opacity: 1;

        transition: opacity 320ms ease-out;
        transition: border 320ms ease-out;
    }

    .ImageOption__Source {
        border-radius: 16px;
        height: 84px;
        width: 84px;
    }

    .Builder__Preview {
        padding: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column-start: 2;
        grid-row-start: 1;
    }

    .Builder__Component {
        border-radius: var(--border-radius);
        background-color: var(--hover-accent-colour);
        display: flex;
        flex-direction: column;
        height: 600px;
        overflow: hidden;
        border: 2px solid var(--main-accent-colour);
        max-width: 540px;
    }

    .Builder__Component__Image {
        width: 100%;
        flex: 1;
        object-fit: cover;
        background-position: center;
        background-size: cover;
    }

    .Builder__Component__ImageSource {
        object-fit: cover;
    }

    .Builder__Component__Content {
        padding: var(--content-padding);
        margin-top: 4px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: var(--content-padding);
    }

    .Builder__Component__Text {
        display: flex;
        flex-direction: column;
        gap: var(--text-spacing)
    }

    .Builder__Component__Title {
        color: var(--main-text-colour);
        font-size: var(--large-font-size);
        font-weight: 700;
        line-height: 100%;
    }

    .Builder__Component__Preview {
        color: var(--main-text-colour);
        font-size: var(--small-font-size);
        font-weight: 400;
        line-height: 140%;
    }

    .Builder__Component__Content>button {
        padding: 12px 16px;
        margin-top: var(--button-margin);
        border-radius: var(--button-border-radius);
        background-color: var(--button-background-color);
        color: var(--button-text-color);
        font-size: var(--small-font-size);
        font-weight: 700;
        line-height: 100%;
    }

    .PaddingSpacing__Small {
        --content-padding: 16px;
        --text-spacing: 16px;
    }

    .PaddingSpacing__Medium {
        --content-padding: 24px;
        --text-spacing: 16px;
    }

    .PaddingSpacing__Large {
        --content-padding: 32px;
        --text-spacing: 20px;
    }

    .PaddingSpacing__XLarge {
        --content-padding: 40px;
        --text-spacing: 20px;
    }

    .BorderRadius__Small {
        --border-radius: 16px;
        --button-border-radius: 8px;
    }

    .BorderRadius__Medium {
        --border-radius: 24px;
        --button-border-radius: 12px;
    }

    .BorderRadius__Large {
        --border-radius: 32px;
        --button-border-radius: 16px;
    }

    .BorderRadius__XLarge {
        --border-radius: 36px;
        --button-border-radius: 20px;
    }

    .Button__Primary {
        --button-background-color: var(--main-action-colour);
        --button-text-color: var(--main-bg-colour);
    }

    .Button__Secondary {
        --button-background-color: var(--hover-accent-colour);
        --button-text-color: var(--main-action-colour);
    }
}

@media (width < 1132px) {

    .Builder {
        display: flex;
        flex-direction: column;
    }

}

@media (width < 861px) {

    .Builder__Content {
        padding: 20px;
    }

}

@media (width < 704px) {

    .ImageOption__Source {
        width: 64px;
        height: 64px;
    }

}

@media (width < 516px) {

    .Builder__Preview {
        padding: 32px 10%
    }

    .Builder__Component {
        height: 460px;
    }

    .Builder__Content {
        padding: 16px;
    }

}