@media screen {

    .Page__Main {
        width: 100vw;
        scroll-behavior: smooth;
    }

    .Header__Main {
        display: flex;
        padding: var(--header-padding);
        align-items: center;
        justify-content: center;
    }

    .Header__Container {
        width: 100%;
        max-width: var(--content-max-width);
    }

    .Header {
        color: var(--main-text-colour);
        font-size: var(--header-font-size);
        line-height: 160%;
        font-style: normal;
        font-weight: 500;
        display: flex;
        flex-wrap: wrap;
        align-items: end;
        max-width: 1064px;

        transition: color ease-out 300ms;
    }

    .Header__Highlight {
        position: relative;
        border-radius: 8px;
        padding: 0px 10px 0px 8px;
        height: 80px;
        display: flex;
        align-items: end;
        margin-left: 8px;
        overflow: hidden;
    }

    .Header__Highlight__Bar {
        position: absolute;
        border-radius: 8px;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: -1;
        transform: translateX(-100%);

    }

    @keyframes highlight-bar-grow {
        to {
            transform: translateX(0px);
        }
    }

    .Designer__Highlight>.Header__Highlight__Bar {
        background-color: var(--green-accent);
        animation: highlight-bar-grow 600ms ease-out forwards;
    }

    .Developer__Highlight>.Header__Highlight__Bar {
        background-color: var(--orange-accent);
        animation: highlight-bar-grow 600ms ease-out forwards 200ms;
    }

    .Divider__Container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0px 148px;
    }

    .Divider {
        border-radius: 1px;
        background-color: rgba(135, 167, 255, 0.04);
        width: 100%;
        max-width: var(--content-max-width);
        height: 4px;
    }

    .Content__Main {
        padding: var(--content-padding);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 188px;
    }

    .Content__Container {
        display: grid;
        max-width: var(--content-max-width);
        grid-template-columns: 292px 1fr;
        gap: 92px;
        width: 100%;
    }

    .Content__Container>h2 {
        transform: translateY(var(--reveal-element-offset));
        opacity: 0;
    }

    .Content__Container>h2.show {
        transform: translateY(0px);
        opacity: 1;

        transition: ease-out 400ms;
    }

    .Content__Container>p {
        transform: translateY(var(--reveal-element-offset));
        opacity: 0;
    }

    .Content__Container>p.show {
        transform: translateY(0px);
        opacity: 1;

        transition: ease-out 400ms;
    }

    .ExperienceCard__Container {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .TechCard__Container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 24px;
    }

    .ContentHeader__Container {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .ContactForm {
        display: flex;
        gap: 16px;
        flex-direction: column;
    }

    .ContactForm__Inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
    }

    .ContactForm__Names {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;
        grid-column: 1 / 3;
    }

    .ContactForm {
        min-height: 160px;
        resize: none;
        position: relative;
    }

    button {
        appearance: none;
        border: none;
        outline: none;
    }

    .ContactForm__Submit {
        display: flex;
        padding: 8px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        background-color: var(--green-accent);
        color: var(--main-bg-colour);
        /* leading-trim: both; */
        /* text-edge: cap; */
        font-size: var(--medium-font-size);
        line-height: 100%;
        font-weight: 600;
        position: absolute;
        bottom: 12px;
        right: 12px;
    }

    .ContactForm__Submit:hover {
        background-color: #009667;
    }

    .ContactForm__Submit:active {
        opacity: 0.6;
    }

}

@media (width < 1358px) {

    .Header {
        max-width: 880px;
    }

    .Header__Highlight {
        height: 60px;
    }

    .Divider__Container {
        padding: 0px 104px;
    }

    .Content__Main {
        gap: 148px;
    }

    .Content__Container {
        grid-template-columns: 216px 1fr;
        gap: 104px;
    }

    .ExperienceCard__Container {
        gap: 16px;
    }

    .TechCard__Container {
        gap: 16px;
    }

    input,
    textarea {
        padding: 16px;
    }

    .ContactForm {
        gap: 12px;
    }

    .ContactForm__Inputs {
        gap: 12px;
    }

    .ContactForm__Names {
        gap: 12px;
    }

    .ContactForm__Submit {
        padding: 8px 16px;
    }

}

@media (width < 1132px) {

    .Divider__Container {
        padding: 0px 64px;
    }

    .Content__Main {
        gap: 148px;
    }

    .Content__Container {
        grid-template-columns: 216px 1fr;
        gap: 64px;
    }
}

@media (width < 988px) {

    .Content__Container {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .Header {
        max-width: 760px;
    }

    .Header__Highlight {
        height: 56px;
        margin-left: 8px;
    }

    .Developer__Highlight {
        margin-top: 0.5em;
    }

}

@media (width < 861px) {

    .Header__Highlight {
        margin-left: 0;
        margin-right: 8px;
    }

}

@media (width < 704px) {

    .Header {
        max-width: 600px;
    }

    .Header__Highlight {
        height: 48px;
    }

    .ContactForm {
        display: grid;
        grid-template-columns: 100%;
    }

    .Divider__Container {
        padding: 0px 32px;
    }

    .Content__Main {
        gap: 64px;
    }

}

@media (width < 516px) {

    .Header {
        max-width: 472px;
    }

    .Header__Highlight {
        height: 9vw;
    }

    .Divider__Container {
        padding: 0px 16px;
    }

    .Content__Main {
        gap: 64px;
    }

    .Content__Container {
        gap: 16px;
    }

    .TechCard__Container {
        gap: 12px;
        grid-template-columns: 1fr 1fr;
    }

    .ContactForm__Inputs {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    .ContactForm__Names {
        gap: 12px;
        display: flex;
        flex-direction: row;
        grid-column: none;
    }

    .ContactForm__Names>input {
        width: 100%;
    }

}