@media screen {

    .TechCard {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 20px;
        align-items: center;
        gap: 28px;
        border-radius: 24px;
        border: 4px solid var(--main-accent-colour);
        overflow: hidden;

        transform: translateY(var(--reveal-element-offset));
        opacity: 0;

        transition: border ease-out 300ms;
    }

    .TechCard.show {
        transform: translateY(0);
        opacity: 1;
        
        transition: ease-out 400ms;
    }

    .TechCard:hover {
        overflow: visible;
    }

    .TechCard__Image {
        width: 48px;
        height: 48px;
        min-width: 48px;
        min-height: 48px;
        border-radius: 8px;
        overflow: hidden;
    }

    .TechCard__Image img {
        object-fit: cover;
    }

    .TechCard__Title {
        color: var(--main-text-colour);
        /* leading-trim: both; */
        /* text-edge: cap; */
        font-size: var(--large-font-size);
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        width: 100%;

        transition: color ease-out 300ms;
    }
}

@media (width < 1358px) {

    .TechCard {
        gap: 16px;
        border-radius: 16px;
    }

    .TechCard__Image {
        width: 40px;
        height: 40px;
        min-width: 40px;
        min-height: 40px;
    }

}

@media (width < 1132px) {

    .TechCard {
        padding: 16px;
    } 
}

@media (width < 988px) {

    .TechCard {
        padding: 20px;
    }

}

@media (width < 704px) {

    .TechCard {
        padding: 16px;
    } 

}

@media (width < 516px) {

    .TechCard {
        padding: 12px;
        border-width: 2px;
    }

    .TechCard__Image {
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        border-radius: 4px;
    }

}