@media screen {

    .Nav__Link {
        width: 124px;
        padding: 12px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--main-text-colour);
        font-size: var(--small-font-size);
        line-height: 16px;
        opacity: 1;
        z-index: 4;

        transition: background-color 200ms ease-out;
        transition: opacity 100ms ease-out;
    }

    .Nav__Link:hover {
        background-color: var(--main-accent-colour);

        transition: background-color 200ms ease-out;
        transition: opacity 100ms ease-out;
    }

    .Nav__Link:active {
        opacity: 0.6;

        transition: opacity 100ms ease-out;
    }

    .Nav__Link__Selected {
        background-color: var(--hover-accent-colour);
        color: var(--main-action-colour);
        font-weight: 600;

        transition: background-color 200ms ease-out;
        transition: opacity 100ms ease-out;
    }

    .Nav__Link__Selected:hover {
        background-color: var(--hover-accent-colour);

        transition: background-color 200ms ease-out;
        transition: opacity 100ms ease-out;
    }

    .Nav__Link__Selected:active {
        opacity: 1;

        transition: opacity 100ms ease-out;
    }

}

@media (width < 704px) {

    .Nav__Link {
        width: 108px;
        padding: 8px;
    }

}

@media (width < 516px) {

    .Nav__Link {
        width: 96px;
        padding: 8px;
    }

}