@media screen {

    .Secondary__Header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: var(--header-padding);
    }

    .Secondary__Header__Container {
        justify-content: center;
        align-items: center;
        display: grid;
        gap: 84px;
        max-width: var(--content-max-width);
    }

    .Secondary__Header__Content {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 24px;
    }

    .Secondary__Header__Text {
        display: flex;
        flex-direction: column;
        gap: 12px;
        grid-row-start: 1;
        grid-column-start: 1;
        width: 100%;
        max-width: var(--content-max-width);
    }

    .Secondary__Header__Content>button {
        border-radius: 12px;
        background: var(--main-action-colour);
        padding: 12px 16px;
        color: var(--main-bg-colour);
        font-size: var(--small-font-size);
        font-weight: 500;
        line-height: 100%;
    }

    .Secondary__Header__Image {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        grid-row-start: 1;
        grid-column-start: 2;
    }

    .Section__Content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 148px;
        padding: var(--content-padding);
    }

}


@media (width < 1132px) {

    .Playground__Builder {
        display: flex;
        flex-direction: column;
    }

}

@media (width < 861px) {

    .Secondary__Header__Container {
        display: flex;
        flex-direction: column;
    }

    .Secondary__Header__Image {
        max-width: 400px;
    }

    .Section__Content {
        gap: 84px;
    }

}