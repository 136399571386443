@media screen {

    .ThemeToggle__Main {
        position: relative;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        gap: 4px;
    }

    .ThemeToggle__Container {
        padding: 10px;
        display: flex;
        opacity: 1;
        border-radius: 8px;
        width: 40px;
        height: 40px;

        transition: background-color 200ms ease-out;
        transition: opacity 100ms ease-out;
    }

    .ThemeToggle__Container:hover {
        background-color: var(--main-accent-colour);

        transition: background-color 200ms ease-out;
        transition: opacity 100ms ease-out;
    }

    .ThemeToggle__Container:active {
        opacity: 0.6;

        transition: opacity 100ms ease-out;
    }

    .ThemeToggle__Container__Selected {
        background-color: transparent;
        opacity: 1;
    }

    .ThemeToggle__Container__Selected:hover {
        background-color: transparent;
        opacity: 1;
    }

    .ThemeToggle__Container__Selected:active {
        background-color: transparent;
        opacity: 1;
    }

    .ThemeToggle_Selection__Dark, .ThemeToggle_Selection__Light {
        position: absolute;
        background: var(--hover-accent-colour);
        border-radius: 8px;
        top: 0px;
        bottom: 0px;
        width: 40px;
        left: 22px;
    }

    .ThemeToggle_Selection__Dark {
        transform: translateX(-22px);

        transition: transform ease-out 300ms;
    }

    .ThemeToggle_Selection__Light {
        transform: translateX(22px);
        
        transition: transform ease-out 300ms;
    }
}

@media (width < 704px) {

    .ThemeToggle__Container {
        width: 32px;
        height: 32px;
        padding: 8px;
    }
    
    .ThemeToggle_Selection__Dark, .ThemeToggle_Selection__Light {
        width: 32px;
        left: 18px;
    }

    .ThemeToggle_Selection__Dark {
        transform: translateX(-18px);

        transition: transform ease-out 300ms;
    }

    .ThemeToggle_Selection__Light {
        transform: translateX(18px);
        
        transition: transform ease-out 300ms;
    }

}