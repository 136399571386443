@media screen {

    .Tooltip__Main {
        display: none;
        position: absolute;
        top: -42px;
        margin-inline: auto;
        padding: 8px 12px;
        border-radius: 8px;
        white-space: nowrap;
        border: 1px solid;
        border-color: var(--hover-accent-colour);
        background-color: var(--main-accent-colour-solid);
        color: var(--main-text-colour);
        font-size: var(--small-font-size);
        line-height: 100%;
    }

    div:hover>.Tooltip__Main,
    a:hover>.Tooltip__Main {
        display: block;
    }

    div:active>.parentIsClickable,
    a:active>.parentIsClickable {
        opacity: 0;

        transition: opacity ease-in 80ms;
    }

    .PersistantTooltip {
        display: block;

        transition: opacity ease-in 160ms;
    }

}

@media (width < 704px) {

    .Tooltip__Main {
        display: none;
    }

    .PersistantTooltip {
        display: block;
    }
    
}