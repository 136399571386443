@media screen {

    .Nav__Main {
        position: sticky;
        top: 0px;
        padding: 16px;
        background-color: var(--main-bg-colour);
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        justify-items: flex-end;
        align-items: center;
        gap: 4px;
        z-index: 2;

        transition: background-color ease-out 300ms;

    }

    .Nav__Blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(32px);
        -webkit-backdrop-filter: blur(32px);
        background-color: var(--transparent-bg-colour);
        z-index: 3;
    }

    .Nav__Links {
        display: flex;
        gap: 4px;
        grid-column: 2/3;
    }

}

@media (width < 516px) {

    .Nav__Main {
        display: flex;
        justify-content: space-between;
    }

}