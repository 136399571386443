.SlidingTab__Main {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.SlidingTab__Container {
    display: grid;
    background-color: var(--main-bg-colour);
    border-radius: 12px;
    padding: 8px;
    gap: 12px;
}

.SlidingTab__Tab {
    color: var(--main-text-colour);
    font-size: var(--xs-font-size);
    line-height: 100%;
    padding: 8px 16px;
    font-weight: 400;
    border-radius: 8px;

    transition: 200ms ease-out;
}

.SlidingTab__Tab:hover {
    background-color: var(--main-accent-colour);

    transition: 200ms ease-out;
}

.SlidingTab__Tab__Selected {
    color: var(--main-action-colour);
    outline: 2px solid var(--main-action-colour);
    background-color: var(--hover-accent-colour);
    font-weight: 600;

    transition: 200ms ease-out;
}

.SlidingTab__Tab__Selected:hover {
    background-color: var(--hover-accent-colour);

    transition: 200ms ease-out;
}