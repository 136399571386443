@media screen {

.EmailLink__Main {
    display: grid;
    grid-template-columns: auto 60px;
    gap: 8px;

    transform: translateY(var(--reveal-element-offset));
    opacity: 1;
}

.EmailLink__Main.show {
    transform: translateY(0px);
    opacity: 1;

    transition: ease-out 400ms;
}

.EmailLink__MailTo__Container,
.EmailLink__Copy__Container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.EmailLink__MailTo,
.EmailLink__Copy {
    background: var(--main-gradient-fill);
    color: var(--main-text-colour);
    font-size: var(--medium-font-size);
    border-radius: 12px;
    width: 100%;
    height: 60px;
    padding: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.EmailLink__MailTo:hover,
.EmailLink__Copy:hover {
    background: var(--hover-gradient-fill);
}

.EmailLink__MailTo:active,
.EmailLink__Copy:active {
    opacity: 0.6;
}

}

@media (width < 516px) {

    .EmailLink__Copy {
        padding: 22px;
    }

}